@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Roboto:wght@900&display=swap);
body {
  margin: 0;
  background-color: black;
}

::selection {
  background: #e21313; /* WebKit/Blink Browsers */
}


.frame-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
}

.frame {
    width: 380px;
    height: 380px;
    /* border: 70px black solid; */
    position: absolute;
}

.border {
  position: absolute;
  top: 45px;
  border: 1px rgba(255, 255, 255, 0.18) solid;
  left: 45px;
  right: 45px;
  bottom: 45px;
}

.work-title {
  transition: all 0.5s;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: white;
  font-size: 35pt;
  font-family: sans-serif;
  position: absolute;
  top: 0px;
  margin: 0px;
  left: 0px;
  -webkit-transform: translateX(-110px) 
  translateY(50px) rotate(-90deg);
          transform: translateX(-110px) 
  translateY(50px) rotate(-90deg);
}

.contact-title {
  transition: all 0.5s;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: white;
  font-size: 35pt;
  font-family: sans-serif;
  position: absolute;
  bottom: 0px;
  margin: 0px;
  right: 0px;
  -webkit-transform: translateX(150px) translateY(-88px) rotate(90deg);
          transform: translateX(150px) translateY(-88px) rotate(90deg);
}

.contact-title:hover {
  opacity: .5;
}

.work-title:hover {
  opacity: .5;
}


  .frame iframe {
    width: 100%;
    height: 100%;
    border: none
  }


.logo-container {
  position: absolute;
  right: 0px;
  bottom: 33px;
  color: white;
  background-color: black;
}


.logo-container h2 {
  font-family: "Roboto Mono";
  margin: 0px;
  font-weight: 300;
  font-size: 20px;
  margin: 0px 8px;
}

.logo-container h3 {
  font-family: "Roboto Mono";
  position: absolute;
  bottom: -15px;
  font-size: 12px;
  font-weight: 100;
  right: 0px;
  margin: 0px;
  margin-right: 8px;
  opacity: .9;
}


.icon-scroll,
.icon-scroll:before{
    position: absolute;
    left: 50%;
    -webkit-transform: scale(.5);
            transform: scale(.5);
    background-color: black;

}

.icon-scroll {
  width: 40px;
  height: 70px;
  margin-left: -20px;
  bottom: 10px;
  margin-top: -35px;
  box-shadow: inset 0 0 0 2px #fff;
  border-radius: 25px;
}

.icon-scroll:before
  {content: '';
  width: 8px;
  height: 8px;
  background: #fff;
  margin-left: -4px;
  top: 8px;
  border-radius: 4px;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: scroll;
          animation-name: scroll;
}

.scroll-container {
  position: absolute;
  bottom: 0px;
  width: 37px;
  height: 62px;
  background-color: black;
}


@-webkit-keyframes scroll{ 
   0%
    {opacity: 1}
  100%
    {opacity: 0;
    -webkit-transform: translateY(46px);
            transform: translateY(46px)
  }
}


@keyframes scroll{ 
   0%
    {opacity: 1}
  100%
    {opacity: 0;
    -webkit-transform: translateY(46px);
            transform: translateY(46px)
  }
}



@media only screen and (max-width: 600px) {
  .frame {
    -webkit-transform: scale(.5);
            transform: scale(.5)
}
.border {
  position: absolute;
  top: 30px;
  border: 1px rgba(255, 255, 255, 0.18) solid;
  left: 20px;
  right: 20px;
  bottom: 30px;
}
.logo-container {
  top: 15.5px;
  left: 20px;
  right: unset;
  bottom: unset;
 -webkit-transform: scale(.8);
         transform: scale(.8);
}

.icon-scroll,
.icon-scroll:before{
    
    -webkit-transform: scale(.4);
    
            transform: scale(.4);

}

.icon-scroll {

  bottom: -4px;

}


}
.greeting-container {

    position: absolute;
      color: white;
      /* background-color: black; */
       padding: 64px; 
      
      /* padding-top: 100vh; */
      /* z-index: 100000; */
      top: 100vh;
      min-height: 71vh;
      /* display: flex; */
  }
  
  .greeting-container h1 {
    font-family: "Roboto", sans-serif;
    
      /* font-weight: 400; */
      letter-spacing: 12px;
      /* text-align: center; */
      margin: 0px;
      font-size: 63pt;
      /* background-color: red; */
      /* font-stretch: expanded; */
  }
  
  .greeting-container p {
    font-family: "Roboto Mono";
      max-width: 510px;
  }
  
  span.underline {
    cursor: pointer;
      background-size: 1px 1em;
      box-shadow: inset 0 -0.05em white, inset 0 -0.2em #000;
      display: inline;
  }


  @media only screen and (max-width: 600px) {
    .greeting-container {
      overflow: hidden;
      width: 100vw;
        position: absolute;
          color: white;
          /* background-color: black; */
           padding: 0px; 
          
          /* padding-top: 100vh; */
          /* z-index: 100000; */
          top: 100vh;
          padding-top: 300px;
          min-height: 30vh;
          /* display: flex; */
      }

      .greeting-container h1 {
        
          /* font-weight: 400; */
          letter-spacing: 12px;
          /* text-align: center; */
          margin: 0px;
          font-size: 30pt;
          padding: 0px 20px;
          /* background-color: red; */
          /* font-stretch: expanded; */
      }

      .greeting-container p {
        padding: 0px 20px;
          max-width: 400px;
          padding-bottom: 20px;
      }
  }
.work .content {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: none;
}

.nav-inner {
margin: auto;
position: relative;
max-width: 150px;
}


.nav-inner h1 {
    text-align: center;
    color: white;
    margin-top: 100px;
    font-family: sans-serif;
    margin-bottom: 60px;
}


.mixtape {
    background-image: url(/static/media/screenshot.68e43987.png);
    background-size: cover;
    width: 800px;
    height: 450px;
    margin: 0px auto;
    border-radius: 5px;
}

.work img {
    position: absolute;
    left: -80px;
    top: -9px;
    width: 52px;
    transition: all 0.5s;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.work img:hover {
    opacity: .5;
}


#container {
    -webkit-perspective: 30px;
            perspective: 30px;
    width: 750px;
    margin: auto;
    height: 450px;
    
  }
  
  #inner {
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
    -webkit-transition: transform 0.5s;
    box-shadow: 2px 2px 50px rgba(0, 0, 0, 0.2);
  }

  .work-text {
    color: white;
    width: 750px;
    margin: auto;
    margin-bottom: 64px;
  }

  .work-image-wrapper {
    width: 750px;
    margin: auto;
    
  }

  .work-text-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

.work-text-icons {
  display: inline-flex;
  align-items: center;
  margin-top: 20px;
  grid-gap: 10px;
  gap: 10px;
}
  .roboto-font-tooltip {
    font-family: 'Roboto Mono'
  }
  /*============================================================
   * EXTRAS -- add bug image
   *============================================================*/
  
  .demo-img {
    height: 450px;
    background-image: url(/static/media/screenshot.68e43987.png);
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 15px;
  }
  
  #container:hover .demo-overlay {
    opacity: 1;
  }
  
  #container:hover {
    cursor: pointer;
  }
  
  .demo-overlay {
    width: 25em;
    padding: 10em 0;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.4s;
  }
  
  .demo-overlay svg {
    display: block;
    margin: 0 auto;
    fill: white;
  }

  .mobile-description {
    display: none;
  }
  

  @media only screen and (max-width: 600px) {
    #container {
      
      width: 300px;
      height: 300px;
    }
    .demo-img {
      height: 300px;
     
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 15px;
    }
.desktop-description {
  display: none;
}

.mobile-description {
  display: block;
}

    .work-text {
    
      width: 300px;
      
    }
  
    .work-image-wrapper {
      width: 300px;
    }
  }
  
  
  @media only screen and (max-height: 666px) {
   
    .nav-inner {
      -webkit-transform: scale(.8) translateY(-80px);
              transform: scale(.8) translateY(-80px)
      }

      
      #container {
        -webkit-transform: translateY(-100px);
                transform: translateY(-100px);
        width: 270px;
        height: 270px;
      }
      .demo-img {
        height: 270px;
        background-image: url(/static/media/mixtape.76e6d9e9.png);
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 15px;
      }
  }
 form  {
  display: flex;
  width: 500px;
  flex-direction: column;
  margin: auto;
  color: white;
}

form p {
  font-size: 12px;
    font-family: "Roboto Mono";
}

form p a {
  color: white;
}

input[type=text],input[type=email], textarea {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  color: white;
  font-family: "Roboto Mono";
  box-sizing: border-box;
  background-color: transparent;
  border: 1.5px solid rgba(255, 255, 255, 0.377);
  transition: 0.5s;
  outline: none;
}

textarea {
  height: 140px;
  resize: none;
}

textarea:invalid {
  box-shadow: none;
}

input[type=text]:focus {
  border: 1.5px solid rgba(255, 255, 255, 0.9);
}

input[type=email]:focus {
  border: 1.5px solid rgba(255, 255, 255, 0.9);
}
textarea:focus {
  border: 1.5px solid rgba(255, 255, 255, 0.9);
}

label { 
  color: white;
  font-family: "Roboto Mono"
}

input[type=submit] {
  width: 100%;
    background-color: white;
    color: black;
    padding: 14px 20px;
    outline: none;
    /* line-height: 9px; */
    letter-spacing: 0px;
    font-weight: 800;
    font-family: sans-serif;
    margin: 8px 0;
    border: none;
    /* border-radius: 4px; */
    cursor: pointer;
  transition: 0.5s;
  -webkit-appearance: none;
appearance: none;
}

input[type=submit]:hover {
  background-color: #ff0018;
  color: white;
}


@media only screen and (max-width: 600px) {
  form  {
    width: 300px;
  }

}


@media only screen and (max-height: 700px) {
  form  {
    width: 270px;
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }

  

}

.App {
  height: 100vh;
  background-color: black;
  background-position: center;
  background-size: cover;

}
* {
  /* Disables pull-to-refresh but allows overscroll glow effects. */
  overscroll-behavior-y: contain;
}
