@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Roboto:wght@900&display=swap');
.greeting-container {

    position: absolute;
      color: white;
      /* background-color: black; */
       padding: 64px; 
      
      /* padding-top: 100vh; */
      /* z-index: 100000; */
      top: 100vh;
      min-height: 71vh;
      /* display: flex; */
  }
  
  .greeting-container h1 {
    font-family: "Roboto", sans-serif;
    
      /* font-weight: 400; */
      letter-spacing: 12px;
      /* text-align: center; */
      margin: 0px;
      font-size: 63pt;
      /* background-color: red; */
      /* font-stretch: expanded; */
  }
  
  .greeting-container p {
    font-family: "Roboto Mono";
      max-width: 510px;
  }
  
  span.underline {
    cursor: pointer;
      background-size: 1px 1em;
      box-shadow: inset 0 -0.05em white, inset 0 -0.2em #000;
      display: inline;
  }


  @media only screen and (max-width: 600px) {
    .greeting-container {
      overflow: hidden;
      width: 100vw;
        position: absolute;
          color: white;
          /* background-color: black; */
           padding: 0px; 
          
          /* padding-top: 100vh; */
          /* z-index: 100000; */
          top: 100vh;
          padding-top: 300px;
          min-height: 30vh;
          /* display: flex; */
      }

      .greeting-container h1 {
        
          /* font-weight: 400; */
          letter-spacing: 12px;
          /* text-align: center; */
          margin: 0px;
          font-size: 30pt;
          padding: 0px 20px;
          /* background-color: red; */
          /* font-stretch: expanded; */
      }

      .greeting-container p {
        padding: 0px 20px;
          max-width: 400px;
          padding-bottom: 20px;
      }
  }