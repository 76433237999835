
.frame-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
}

.frame {
    width: 380px;
    height: 380px;
    /* border: 70px black solid; */
    position: absolute;
}

.border {
  position: absolute;
  top: 45px;
  border: 1px rgba(255, 255, 255, 0.18) solid;
  left: 45px;
  right: 45px;
  bottom: 45px;
}

.work-title {
  transition: all 0.5s;
  cursor: pointer;
  user-select: none;
  color: white;
  font-size: 35pt;
  font-family: sans-serif;
  position: absolute;
  top: 0px;
  margin: 0px;
  left: 0px;
  transform: translateX(-110px) 
  translateY(50px) rotate(-90deg);
}

.contact-title {
  transition: all 0.5s;
  cursor: pointer;
  user-select: none;
  color: white;
  font-size: 35pt;
  font-family: sans-serif;
  position: absolute;
  bottom: 0px;
  margin: 0px;
  right: 0px;
  transform: translateX(150px) translateY(-88px) rotate(90deg);
}

.contact-title:hover {
  opacity: .5;
}

.work-title:hover {
  opacity: .5;
}


  .frame iframe {
    width: 100%;
    height: 100%;
    border: none
  }


.logo-container {
  position: absolute;
  right: 0px;
  bottom: 33px;
  color: white;
  background-color: black;
}


.logo-container h2 {
  font-family: "Roboto Mono";
  margin: 0px;
  font-weight: 300;
  font-size: 20px;
  margin: 0px 8px;
}

.logo-container h3 {
  font-family: "Roboto Mono";
  position: absolute;
  bottom: -15px;
  font-size: 12px;
  font-weight: 100;
  right: 0px;
  margin: 0px;
  margin-right: 8px;
  opacity: .9;
}


.icon-scroll,
.icon-scroll:before{
    position: absolute;
    left: 50%;
    transform: scale(.5);
    background-color: black;

}

.icon-scroll {
  width: 40px;
  height: 70px;
  margin-left: -20px;
  bottom: 10px;
  margin-top: -35px;
  box-shadow: inset 0 0 0 2px #fff;
  border-radius: 25px;
}

.icon-scroll:before
  {content: '';
  width: 8px;
  height: 8px;
  background: #fff;
  margin-left: -4px;
  top: 8px;
  border-radius: 4px;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: scroll;
}

.scroll-container {
  position: absolute;
  bottom: 0px;
  width: 37px;
  height: 62px;
  background-color: black;
}


@keyframes scroll{ 
   0%
    {opacity: 1}
  100%
    {opacity: 0;
    transform: translateY(46px)
  }
}



@media only screen and (max-width: 600px) {
  .frame {
    transform: scale(.5)
}
.border {
  position: absolute;
  top: 30px;
  border: 1px rgba(255, 255, 255, 0.18) solid;
  left: 20px;
  right: 20px;
  bottom: 30px;
}
.logo-container {
  top: 15.5px;
  left: 20px;
  right: unset;
  bottom: unset;
 transform: scale(.8);
}

.icon-scroll,
.icon-scroll:before{
    
    transform: scale(.4);

}

.icon-scroll {

  bottom: -4px;

}


}