 form  {
  display: flex;
  width: 500px;
  flex-direction: column;
  margin: auto;
  color: white;
}

form p {
  font-size: 12px;
    font-family: "Roboto Mono";
}

form p a {
  color: white;
}

input[type=text],input[type=email], textarea {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  color: white;
  font-family: "Roboto Mono";
  box-sizing: border-box;
  background-color: transparent;
  border: 1.5px solid rgba(255, 255, 255, 0.377);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}

textarea {
  height: 140px;
  resize: none;
}

textarea:invalid {
  box-shadow: none;
}

input[type=text]:focus {
  border: 1.5px solid rgba(255, 255, 255, 0.9);
}

input[type=email]:focus {
  border: 1.5px solid rgba(255, 255, 255, 0.9);
}
textarea:focus {
  border: 1.5px solid rgba(255, 255, 255, 0.9);
}

label { 
  color: white;
  font-family: "Roboto Mono"
}

input[type=submit] {
  width: 100%;
    background-color: white;
    color: black;
    padding: 14px 20px;
    outline: none;
    /* line-height: 9px; */
    letter-spacing: 0px;
    font-weight: 800;
    font-family: sans-serif;
    margin: 8px 0;
    border: none;
    /* border-radius: 4px; */
    cursor: pointer;
    -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-appearance: none;
-moz-appearance: none;
appearance: none;
}

input[type=submit]:hover {
  background-color: #ff0018;
  color: white;
}


@media only screen and (max-width: 600px) {
  form  {
    width: 300px;
  }

}


@media only screen and (max-height: 700px) {
  form  {
    width: 270px;
    transform: translateY(-100px);
  }

  

}
