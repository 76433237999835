.work .content {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: none;
}

.nav-inner {
margin: auto;
position: relative;
max-width: 150px;
}


.nav-inner h1 {
    text-align: center;
    color: white;
    margin-top: 100px;
    font-family: sans-serif;
    margin-bottom: 60px;
}


.mixtape {
    background-image: url('./assets/screenshot.png');
    background-size: cover;
    width: 800px;
    height: 450px;
    margin: 0px auto;
    border-radius: 5px;
}

.work img {
    position: absolute;
    left: -80px;
    top: -9px;
    width: 52px;
    transition: all 0.5s;
    cursor: pointer;
    user-select: none;
}

.work img:hover {
    opacity: .5;
}


#container {
    perspective: 30px;
    width: 750px;
    margin: auto;
    height: 450px;
    
  }
  
  #inner {
    transition: transform 0.5s;
    -webkit-transition: transform 0.5s;
    box-shadow: 2px 2px 50px rgba(0, 0, 0, 0.2);
  }

  .work-text {
    color: white;
    width: 750px;
    margin: auto;
    margin-bottom: 64px;
  }

  .work-image-wrapper {
    width: 750px;
    margin: auto;
    
  }

  .work-text-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

.work-text-icons {
  display: inline-flex;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}
  .roboto-font-tooltip {
    font-family: 'Roboto Mono'
  }
  /*============================================================
   * EXTRAS -- add bug image
   *============================================================*/
  
  .demo-img {
    height: 450px;
    background-image: url('./assets/screenshot.png');
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 15px;
  }
  
  #container:hover .demo-overlay {
    opacity: 1;
  }
  
  #container:hover {
    cursor: pointer;
  }
  
  .demo-overlay {
    width: 25em;
    padding: 10em 0;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.4s;
  }
  
  .demo-overlay svg {
    display: block;
    margin: 0 auto;
    fill: white;
  }

  .mobile-description {
    display: none;
  }
  

  @media only screen and (max-width: 600px) {
    #container {
      
      width: 300px;
      height: 300px;
    }
    .demo-img {
      height: 300px;
     
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 15px;
    }
.desktop-description {
  display: none;
}

.mobile-description {
  display: block;
}

    .work-text {
    
      width: 300px;
      
    }
  
    .work-image-wrapper {
      width: 300px;
    }
  }
  
  
  @media only screen and (max-height: 666px) {
   
    .nav-inner {
      transform: scale(.8) translateY(-80px)
      }

      
      #container {
        transform: translateY(-100px);
        width: 270px;
        height: 270px;
      }
      .demo-img {
        height: 270px;
        background-image: url('./assets/mixtape.png');
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 15px;
      }
  }