.App {
  height: 100vh;
  background-color: black;
  background-position: center;
  background-size: cover;

}
* {
  /* Disables pull-to-refresh but allows overscroll glow effects. */
  overscroll-behavior-y: contain;
}